export const premadePuzzleTypes = [
  "puzzle_f0",
  "puzzle_b1",
  "puzzle_e0",
  "puzzle_c1",
  "puzzle_c2",
  "puzzle_f1",
  "puzzle_a2",
  "puzzle_d0",
  "puzzle_c0",
  "puzzle_a0",
  "puzzle_a1",
  "puzzle_b0",
];

const limitedSeriesPuzzlePrefixL0 = "puzzle_l0_";
const limitedSeriesPuzzlePrefixL1 = "puzzle_l1_";

export const limitedSeriesPuzzleTypes = [
  `${limitedSeriesPuzzlePrefixL1}0`,
  `${limitedSeriesPuzzlePrefixL0}5`,
  `${limitedSeriesPuzzlePrefixL0}8`,
  `${limitedSeriesPuzzlePrefixL1}5`,
  `${limitedSeriesPuzzlePrefixL0}6`,
  `${limitedSeriesPuzzlePrefixL1}3`,
  `${limitedSeriesPuzzlePrefixL1}4`,
  `${limitedSeriesPuzzlePrefixL0}0`,
  `${limitedSeriesPuzzlePrefixL0}4`,
  `${limitedSeriesPuzzlePrefixL0}9`,
  `${limitedSeriesPuzzlePrefixL0}10`,
  `${limitedSeriesPuzzlePrefixL0}11`,
  // `${limitedSeriesPuzzlePrefixL0}1`,
  // `${limitedSeriesPuzzlePrefixL0}2`,
  // `${limitedSeriesPuzzlePrefixL0}3`,
  // `${limitedSeriesPuzzlePrefixL1}1`,
  // `${limitedSeriesPuzzlePrefixL1}2`,
  // `${limitedSeriesPuzzlePrefixL0}7`,
];

export const customPuzzle = "puzzle_custom";

const giftPrices = {
  puzzle_custom: 34.99,
  puzzle_a0: 31.89,
  puzzle_a1: 30.99,
  puzzle_a2: 32.59,
  puzzle_b0: 31.29,
  puzzle_b1: 33.59,
  puzzle_c0: 32.39,
  puzzle_c1: 34.99,
  puzzle_c2: 36.99,
  puzzle_d0: 31.59,
  puzzle_e0: 32.99,
  puzzle_f0: 35.89,
  puzzle_f1: 32.89,
};

export const getGiftPrice = (giftType) => {
  for (const [prefix, price] of Object.entries({
    [limitedSeriesPuzzlePrefixL0]: 37.0,
    [limitedSeriesPuzzlePrefixL1]: 39.0,
  })) {
    if (giftType.startsWith(prefix)) {
      return price;
    }
  }

  return giftPrices[giftType];
};

export const calculateDiscount = (price, discount) => {
  let newPrice = price - (price * discount) / 100;
  newPrice = Math.round(newPrice * 100) / 100;
  return newPrice;
};
