import { calculateDiscount } from "./utils";

function Price(props) {
  const nonDiscountablePrice = props.nonDiscountablePrice
    ? props.nonDiscountablePrice
    : 0;
  const discountablePrice = props.price ? props.price : 0;
  const discount = props.discount ? props.discount : 0;
  const finalPriceWithoutDiscount = (
    discountablePrice + nonDiscountablePrice
  ).toFixed(2);
  const finalPriceWithDiscount = (
    calculateDiscount(discountablePrice, discount) + nonDiscountablePrice
  ).toFixed(2);

  if (discount === 0) {
    return (
      <span style={{ fontFamily: "none" }}>{finalPriceWithDiscount} лв</span>
    );
  }

  return (
    <>
      <span style={{ fontFamily: "none", textDecoration: "line-through" }}>
        {finalPriceWithoutDiscount} лв
      </span>

      <span style={{ fontFamily: "none", color: "#fdc43f" }}>
        {finalPriceWithDiscount} лв
      </span>
    </>
  );
}

export default Price;
