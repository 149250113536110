import React from "react";
import { Asterisk, CaretLeft, CaretRight, PuzzlePiece } from "phosphor-react";
import { getGiftPrice } from "./utils";
import Price from "./Price";

function FormGiftPuzzleVariationPicker(props) {
  const [startingIndex, setStartingIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(4);
  const maxIndex =
    Math.floor((props.puzzleTypes.length - 1) / pageSize) * pageSize;
  const goToPrevPage = () => {
    setStartingIndex((curr) => Math.max(curr - pageSize, 0));
  };
  const goToNextPage = () => {
    setStartingIndex((curr) => Math.min(curr + pageSize, maxIndex));
  };

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setPageSize(4);
      } else {
        setPageSize(6);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <label htmlFor="form_gift_type" className="vertical-alignment">
        <PuzzlePiece size={16} weight="bold" className="neutral-color" />{" "}
        Изберете дизайн{" "}
        <Asterisk size={16} weight="bold" className="form-label-asterisk" />
      </label>
      {props.form_gift_type_error && (
        <span className="form-error-message">
          {props.form_gift_type_error.message}
        </span>
      )}
      <div className="form-gift-variations">
        <span
          className="carousel-prev"
          style={startingIndex == 0 ? { display: "none" } : {}}
          onClick={goToPrevPage}
        >
          <CaretLeft size={20} weight="bold" />
        </span>
        {props.puzzleTypes
          .slice(startingIndex, startingIndex + pageSize)
          .map((puzzleType) => (
            <div key={puzzleType} className="form-gift-variations-block">
              <label>
                <input
                  type="radio"
                  id="form_gift_type"
                  {...props.register("form_gift_type", {
                    required: "Моля, изберете подарък.",
                  })}
                  value={puzzleType}
                  hidden
                />
                <img
                  src={"gallery-" + puzzleType + ".png"}
                  alt={"Image " + puzzleType}
                  className={
                    props.formGiftType === puzzleType
                      ? "form-gift-variation form-gift-selected-variation"
                      : "form-gift-variation"
                  }
                  onClick={() => props.handlePuzzleVariationChange(puzzleType)}
                />
              </label>
              <Price
                price={getGiftPrice(puzzleType)}
                discount={props.promoCodeDiscount}
              />
            </div>
          ))}
        <span
          className="carousel-next"
          style={startingIndex == maxIndex ? { display: "none" } : {}}
          onClick={goToNextPage}
        >
          <CaretRight size={20} weight="bold" />
        </span>
      </div>
    </>
  );
}

export default FormGiftPuzzleVariationPicker;
